<template>
  <div v-if="isQuizDataLoading"></div>
  <template v-else>
    <div v-show="isLoading" class="quiz-results">
      <div class="quiz-results__body">
        <a-skeleton-button
          style="width: 327px; height: 32px; margin-top: 76px"
          active
          block
          shape="round"
        />
        <a-skeleton-button
          style="height: 184px; margin-top: 32px"
          block
          active
          shape="round"
        />
        <a-skeleton-button
          style="width: 327px; height: 32px; margin-top: 76px"
          active
          block
          shape="round"
        />
        <a-skeleton-button
          style="height: 184px; margin-top: 32px"
          block
          active
          shape="round"
        />
      </div>
    </div>
    <div v-show="!isLoading" class="">
      <div v-if="isNewPaywall">
        <new-paywall
          :quizResult="quizData"
          @scroll="onScroll"
          @subscribe="onSubscribe"
          @startTrial="onStartTrial"
          :redirect="`/home`"
        />
      </div>
      <!-- <subscription-modal :isShowRecommendation="true" @done="onDone" /> -->
    </div>
  </template>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import SubscriptionModal from '@/components/subscribe/SubscriptionModal.vue'
import { userUpdate } from '@/services/user.service'
import { purchaseTypes } from '@/data/constants'
import PaymentsModal from '@/components/subscribe/PaymentsModal.vue'
import { anxietyScores } from '@/components/quiz/quiz-data'
import { getCurrency } from '@/utils/helpers'
import { loadFacebookPixel } from '@/utils/facebookConfig'
import { metaEventCustomActions } from '@/data/constants'
import { getLead } from '@/services/lead.service'
import moment from 'moment'
import { packagePlaces } from '@/data/constants'
import { getCountryByIp } from '@/services/other.service'
import { currency } from '@/data/constants'
import Paywall from '../components/quiz/paywall/Paywall.vue'
import PaywallDynamic from '../components/quiz/paywall/PaywallDynamic.vue'
import NewPaywall from '../components/quiz/paywall/NewPaywall.vue'
import PaywallVideo from '../components/quiz/paywall/PaywallVideo.vue'

export default {
  components: {
    SubscriptionModal,
    PaymentsModal,
    Paywall,
    PaywallDynamic,
    PaywallVideo,
    PaywallDynamic,
    NewPaywall,
  },
  name: 'QuizDynamicResults',
  data() {
    return {
      purchaseTypes,
      isOpen: false,
      anxietyScores,
      isLoading: false,
      isNewPaywall: true,
      selectedPachage: null,
    }
  },
  computed: {
    ...mapState('quiz', ['quizForm', 'quizData', 'isQuizDataLoading']),
    ...mapState(['user']),
    ...mapState(['token']),
    ...mapState('packageModule', ['packages']),
    freeTrial() {
      return this.packages.find((i) => i.months === 12) || {}
    },
    perMonth() {
      return (this.freeTrial.amount / 12).toFixed(2)
    },
    currency() {
      return this.getCurrency(this.freeTrial.currency)
    },
    anxietyScore() {
      let values = []
      if (this.user.goal && this.user.goal.length) {
        this.user.goal.forEach((i) => {
          const v = this.anxietyScores[i]
          if (v && values.every((j) => v[1] > j)) {
            values = v
          }
        })
      }
      const random = Math.random() * (values[1] - values[0]) + values[0]
      return random ? random.toFixed(0) : 90
    },
    isLeadQuizResult() {
      return this.$route.name === 'LeadDynamicResult'
    },
    trialEndDay() {
      const date = moment().add(7, 'days')
      return date.format('Do MMM YY')
    },
    selectedGoals() {},
  },
  async created() {
    if (!this.quizData.slug) {
      await this.fetchCustomQuiz(this.$route.params.slug)
    }

    getCountryByIp()
      .then((res) => {
        this.isEurope = res.timezone.split('/')[0] === 'Europe'
        this.fetchPackage({
          place: packagePlaces.AFTER_LOCKED_CONTENT,
          currency: this.isEurope ? currency.EUR : currency.USD,
        })
      })
      .catch((err) => {
        console.log(err)
        this.fetchPackage({
          place: packagePlaces.AFTER_LOCKED_CONTENT,
          currency: currency.USD,
        })
      })
    if (!this.isLeadQuizResult) {
      this.setup()
    } else {
      getLead(this.$route.query.statistic_id).then((res) => {
        this.SET_USER({ ...res, ...res.quiz_fields })
      })
    }
  },
  async mounted() {
    if (this.$route.query.a === 'view-plan') {
      this.SET_OPEN({
        isOpen: true,
        action: metaEventCustomActions.ON_QUIZ_FINISHED,
      })
    }
    this.loadFacebookPixel('ResultScreen')
  },
  methods: {
    ...mapActions('packageModule', ['fetchPackage']),
    ...mapActions('quiz', ['fetchCustomQuiz']),
    ...mapMutations('subscription', ['SET_OPEN']),
    ...mapActions(['fetchUser']),
    ...mapMutations(['SET_USER']),

    async setup() {
      this.isLoading = true
      if (this.quizForm.isQuizCompleted) {
        delete this.quizForm.email

        try {
          await userUpdate(this.quizForm)
        } catch (e) {
          console.log(e)
        }
      }

      this.fetchUser().finally(() => (this.isLoading = false))
      localStorage.removeItem('quizForm')
      localStorage.removeItem('progress')
      localStorage.removeItem('isQuiz')
      localStorage.removeItem('statistic_id')
    },

    loadFacebookPixel,
    getCurrency,
    onSubscribe() {
      if (this.isLeadQuizResult) {
        this.leadRedirect()
      } else {
        this.SET_OPEN({
          isOpen: true,
          action: metaEventCustomActions.ON_QUIZ_FINISHED,
        })
      }
    },
    leadRedirect() {
      this.$router.push({
        name: 'CreateAccount',
        query: {
          redirect: '/subscription',
          u: 'lead',
          statistic_id: this.$route.query.statistic_id,
          ...this.$route.query,
          redirectParams: JSON.stringify({
            dynamic: this.isLeadQuizResult ? true : false,
          }),
        },
      })
    },
    onDone() {
      this.SET_OPEN({ isOpen: false })
      window.location.href = '/'
    },
    onScroll() {
      if (this.isLeadQuizResult) {
        this.leadRedirect()
      } else {
        const d = document.getElementById('packages')
        d.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    },
    onClose() {
      this.$router.push('/')
    },
    onStartTrial() {
      if (this.isLeadQuizResult) {
        this.leadRedirect()
      } else this.isOpen = true
    },
  },
}
</script>

<style lang="scss">
.quiz-results {
  background: linear-gradient(341.28deg, #71b280 -33.77%, #134e5e 114.22%);
  min-height: 100vh;
  padding: 36px 36px 72px;

  @media screen and (max-width: 576px) {
    padding: 20px 6px;
  }

  &__header {
    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  &__body {
    max-width: 327px;
    margin: 0 auto;

    &--lg {
      max-width: 824px;
      width: 100%;
      @media screen and (max-width: 500px) {
        max-width: 327px;
      }
    }

    .ant-skeleton-element .ant-skeleton-input {
      height: 100%;
    }

    .ant-skeleton-element .ant-skeleton-button {
      height: 100%;
    }
  }

  &__video {
    position: relative;

    &__play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-tag {
      border-radius: 12px;
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: var(--color-white);
    font-weight: 700;
    margin-top: 76px;
    margin-bottom: 32px;
  }

  &__percent {
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: var(--color-white);
    font-weight: 700;
    margin: 9px 0 4px;
  }

  &__logo {
    display: grid;
    justify-items: center;
    margin-top: 33px;
  }

  &__text {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--color-white);
    font-weight: 500;
    max-width: 237px;
    margin-bottom: 24px;

    &--big {
      font-size: 48px;
      line-height: 66px;
      font-weight: 500;
    }

    &--small {
      line-height: 21px;
    }

    &--medium {
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      margin: 24px 0 12px;
    }
  }

  &__img {
    margin: 0 0 24px 0;
  }

  &__list {
    list-style: none;
    width: 100%;
    display: grid;
    row-gap: 22px;
  }

  &__item {
    display: flex;
    align-items: center;

    & > img {
      width: 24px;
      height: 24px;
    }
  }

  &__item-text {
    margin-left: 8px;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    font-weight: 400;
  }

  &__card {
    background: rgba(#ffffff, 0.1);
    border-radius: 12px;

    &--big {
      display: grid;
      justify-self: center;
      justify-content: center;
      width: 100%;
      padding: 21px 0 27px;
      margin: 36px 0;
    }
  }

  &__sub-title {
    font-size: 24px;
    line-height: 33px;
    color: var(--color-white);
    font-weight: 700;
    margin: 0;
    text-align: center;
  }

  &__mind-profile {
    padding: 16px;
    display: flex;
    width: 100%;

    &__left {
      display: grid;
      justify-items: center;
      margin-right: 23px;
    }

    &__right {
      display: grid;
      row-gap: 2px;
    }

    &__text {
      font-size: 14px;
      line-height: 21px;
      color: var(--color-white);
      font-weight: 700;
    }

    &__label {
      font-size: 16px;
      line-height: 21px;
      color: var(--color-white);
      font-weight: 400;
    }

    &__wrapper {
      display: grid;
      row-gap: 16px;
      margin: 23px 0 80px;
    }
  }

  &__btn {
    padding: 17px 0;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
  }

  &__p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--color-white);
    margin: 35px auto 20px;
    max-width: 239px;

    strong {
      font-weight: 700;
      font-weight: 700;
    }
  }

  &__btn-secondary {
    background: inherit;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    width: 100%;
    color: var(--color-white);
    border-radius: 58px;
    padding: 17px 0;
    margin-top: 10px;
    transition: background 300ms ease-in-out;

    &:hover {
      background: rgba(#ffffff, 0.1);
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    color: var(--color-white);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    gap: 26px;
    margin-top: 20px;

    li {
      &:not(:first-child) {
        list-style-type: disc;
      }
    }
  }
}

.purchase__trial-work {
  color: var(--color-white);
  padding: 0;

  &__close {
    position: absolute;
    right: 36px;
    background: inherit;
    border: none;

    @media screen and (max-width: 500px) {
      right: 5px;
    }
  }
}

.m-space {
  margin: 80px 0 29px;
}

.m-space-1 {
  margin: 80px 0 114px;
}

.m-space-2 {
  margin: 32px 0 116px;
}

.m-space-3 {
  margin: 0 0 124px;
}
</style>
